/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Theta frekansı nedir ?")), "\n", React.createElement(_components.p, null, "Beyin frekanslarından biridir."), "\n", React.createElement(_components.p, null, "Beynimiz çeşitli bilinç durumlarında farklı frekanslarda çalışır."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Gama frekansı:"), " Saniyede 40-5000 Hz arasında döngü sağlar. Algının ve tepki vermenin en hızlı çalıştığı frekanstır."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Beta frekansı :"), " Saniyede 14-28 Hz arasında döngü sağlar."), "\n", React.createElement(_components.p, null, "Günlük hayatta insanlarla ve çevremizle iletişim kurduğumuz frekanstır."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Alfa frekansı:"), " Saniyede 7-14 Hz arasında döngü sağlar."), "\n", React.createElement(_components.p, null, "Gevşemiş ve meditasyon durumudur.Hayalleri yönetir."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Theta frekansı:"), " Saniyede 4-7 Hz arasında döngü sağlar . Çok derin gevşeme ,hipnoz halidir. Derin uykuda olduğumuz ve rüya gördüğümüz beyin frekansıdır. Theta dalgaları her zaman mükemmel bir yaratıcıdır,ilham duygularıyla karakterize edilir ve maneviyatı çok yüksektir**"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Delta frekansı:"), " Saniyede 0-4 Hz arasında döngü sağlar."), "\n", React.createElement(_components.p, null, "Çok derin uyku halidir."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
